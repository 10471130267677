import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="Privacy policy" />
      <article className="basic">
        <header>
          <h1>Privacy policy</h1>
        </header>
        <main>
          <section id="introduction">
            <main>
              <h2>Introduction</h2>
              <p>
                Varia, Inc ("Company" or "We") respect your privacy and are
                committed to protecting it through our compliance with this
                policy.
              </p>
              <p>
                This policy describes the types of information we may collect
                from you or that you may provide when you visit the website
                variametrix.com (our "Website") and our practices for
                collecting, using, maintaining, protecting, and disclosing that
                information.
              </p>
              <p>This policy applies to information we collect:</p>
              <ul>
                <li>
                  <p>On this Website.</p>
                </li>
                <li>
                  <p>
                    In email, text, and other electronic messages between you
                    and this Website.
                  </p>
                </li>
                <li>
                  <p>
                    When you interact with our advertising and applications on
                    third-party websites and services, if those applications or
                    advertising include links to this policy.
                  </p>
                </li>
                <li>
                  <p>
                    Information submitted or uploaded through VariaMetrix for
                    analysis by the VariaMetrix tool or staff of Varia, Inc.
                  </p>
                </li>
              </ul>
              <p>It does not apply to information collected by:</p>
              <ul>
                <li>
                  <p>
                    Us offline or through any other means, including on any
                    other website operated by the Company or any third party; or
                  </p>
                </li>
                <li>
                  <p>
                    Any third party (including our affiliates and subsidiaries),
                    including through any application or content (including
                    advertising) that may link to or be accessible from the
                    Website
                  </p>
                </li>
              </ul>
              <p>
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, your choice
                is not to use our Website. By accessing or using this Website,
                you agree to this privacy policy. This policy may change from
                time to time (see{" "}
                <a href="#changes">Changes to Our Privacy Policy</a>). Your
                continued use of this Website after we make changes is deemed to
                be acceptance of those changes, so please check the policy
                periodically for updates.
              </p>
            </main>
          </section>
          <section id="children">
            <main>
              <h2>Children Under the Age of 13</h2>
              <p>
                Our Website is not intended for children under 13 years of age.
                No one under age 13 may provide any information to the Website.
                We do not knowingly collect personal information from children
                under 13. If you are under 13, do not use or provide any
                information on this Website or through any of its features,
                register on the Website, make any purchases through the Website,
                or provide any information about yourself to us, including your
                name, address, telephone number, email address, or any screen
                name or user name you may use. If we learn we have collected or
                received personal information from a child under 13 without
                verification of parental consent, we will delete that
                information. If you believe we might have any information from
                or about a child under 13, please contact us at{" "}
                <a href="tel:+18882030423">(888) 203-0423</a>.
              </p>
              <p>
                California residents under 16 years of age may have additional
                rights regarding the collection and sale of their personal
                information. Please see{" "}
                <a href="#california">Your California Privacy Rights</a> for
                more information.
              </p>
            </main>
          </section>
          <section id="collected-info">
            <main>
              <h2>Information We Collect About You and How We Collect It</h2>
              <p>
                We collect several types of information from and about users of
                our Website, including information:
              </p>
              <ul>
                <li>
                  By which you may be personally identified, such as name,
                  postal address, e-mail address, or telephone number ("personal
                  information");
                </li>
                <li>
                  That is about you but individually does not identify you, such
                  as subscription information, company information, company
                  name, postal address, phone number
                </li>
                <li>
                  About your internet connection, the equipment you use to
                  access our Website, and usage details.
                </li>
              </ul>
              <p>We collect this information:</p>
              <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information
                  collected automatically may include usage details, IP
                  addresses, and information collected through cookies, link
                  clicks or taps, time on site, page usage, and exit pages.
                </li>
                <li>
                  API integrations through 3rd party services as authorized by
                  you.
                </li>
              </ul>
            </main>
          </section>
          <section id="provided-info">
            <main>
              <h2>Information You Provide to Us</h2>
              <p>
                The information we collect on or through our Website may
                include:
              </p>
              <ul>
                <li>
                  <p>
                    Information that you provide by filling in forms on our
                    Website. This includes information provided at the time of
                    registering to use our Website, subscribing to our service,
                    or requesting further services. We may also ask you for
                    information when you report a problem with our Website.
                  </p>
                </li>
                <li>
                  <p>
                    Records and copies of your correspondence (including email
                    addresses), if you contact us.
                  </p>
                </li>
                <li>
                  <p>
                    Your responses to surveys that we might ask you to complete
                    for research purposes.
                  </p>
                </li>
                <li>
                  <p>
                    Details of transactions you carry out through our Website
                    and of the fulfillment of your orders. You may be required
                    to provide financial information before placing an order
                    through our Website.
                  </p>
                </li>
                <li>
                  <p>
                    Information about your company and your associates you
                    invite to access your account
                  </p>
                </li>
              </ul>
            </main>
          </section>
          <section id="automatic">
            <main>
              <h2>
                Information We Collect Through Automatic Data Collection
                Technologies
              </h2>
              <p>
                As you navigate through and interact with our Website, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </p>
              <ul>
                <li>
                  <p>
                    Details of your visits to our Website, including traffic
                    data, location data, logs, and other communication data and
                    the resources that you access and use on the Website.
                  </p>
                </li>
                <li>
                  <p>
                    Information about your computer and internet connection,
                    including your IP address, operating system, and browser
                    type. We also may use these technologies to collect
                    information about your online activities over time and
                    across third-party websites or other online services
                    (behavioral tracking).
                  </p>
                </li>
              </ul>
              <p>
                The information we collect automatically is only statistical
                data and does not include personal information, but we may
                maintain it or associate it with personal information we collect
                in other ways or receive from third parties. It helps us to
                improve our Website and to deliver a better and more
                personalized service, including by enabling us to:
              </p>
              <ul>
                <li>
                  <p>Estimate our audience size and usage patterns.</p>
                </li>
                <li>
                  <p>
                    Store information about your preferences, allowing us to
                    customize our Website according to your individual
                    interests.
                  </p>
                </li>
                <li>
                  <p>Speed up your searches.</p>
                </li>
                <li>
                  <p>Recognize you when you return to our Website.</p>
                </li>
              </ul>
              <p>
                The technologies we use for this automatic data collection may
                include:
              </p>
              <ul>
                <li>
                  <p>
                    Cookies (or browser cookies). A cookie is a small file
                    placed on the hard drive of your computer. You may refuse to
                    accept browser cookies by activating the appropriate setting
                    on your browser. However, if you select this setting you may
                    be unable to access certain parts of our Website. Unless you
                    have adjusted your browser setting so that it will refuse
                    cookies, our system will issue cookies when you direct your
                    browser to our Website.
                  </p>
                </li>
                <li>
                  <p>
                    Local Storage. Certain features of our Website may use local
                    stored objects to collect and store information about your
                    preferences and navigation to, from, and on our Website.
                    These data are not managed by the same browser settings as
                    are used for browser cookies. For information about managing
                    your privacy and security settings, see{" "}
                    <a href="#choices">
                      Choices About How We Use and Disclose Your Information
                    </a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Web Beacons. Pages of our the Website and our e-mails may
                    contain small electronic files known as web beacons (also
                    referred to as clear gifs, pixel tags, and single-pixel
                    gifs) that permit the Company, for example, to count users
                    who have visited those pages or opened an email and for
                    other related website statistics (for example, recording the
                    popularity of certain website content and verifying system
                    and server integrity).
                  </p>
                </li>
              </ul>
              <p>
                We do not collect personal information automatically, but we may
                tie this information to personal information about you that we
                collect from other sources or you provide to us.
              </p>
            </main>
          </section>
          <section id="google">
            <main>
              <h2>Google User Data</h2>
              <p>
                You may connect your Google Account data through VariaMetrix via
                OAuth authentication mechanisms. We do not store passwords. We
                do store your account name a unique token that allows
                VariaMetrix to identify itself to Google in order to access
                data.
              </p>
              <h3>Google Analytics</h3>
              <p>
                We use the data accessed through the Google Analytics API only
                to provide you with reports of that data in the VariaMetrix
                dashboard and to export into PowerPoint presentations and CSV
                files. This data is not stored on VariaMetrix servers and is
                accessed as-needed from the client-side application.
              </p>
              <h3>Google AdWords</h3>
              <p>
                We use the data accessed through the AdWords API only to provide
                you with reports of that data in the VariaMetrix dashboard and
                to export into PowerPoint presentations and CSV files.
                VariaMetrix stores the following identifiers:
              </p>
              <ul>
                <li>Account ID and name</li>
                <li>Campaign IDs and names</li>
                <li>Ad group IDs and names</li>
                <li>Ad units IDs and names</li>
              </ul>
              <p>
                Specific usage and analytical data related to these identifiers
                is accessed as-needed from the client-side application.
              </p>
            </main>
          </section>
          <section id="third-parties">
            <main>
              <h2>
                Third-Party Use of Cookies and Other Tracking Technologies
              </h2>
              <p>
                Some content or applications on the Website may be served by
                third parties, including content providers and application
                providers. These third parties may use cookies alone or in
                conjunction with web beacons or other tracking technologies to
                collect information about you when you use our website. The
                information they collect may be associated with your personal
                information or they may collect information, including personal
                information, about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with interest-based (behavioral)
                advertising or other targeted content.
              </p>
              <p>
                We do not control these third parties' tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly. For information about how you can
                opt out of receiving targeted advertising from many providers,
                see{" "}
                <a href="#choices">
                  Choices About How We Use and Disclose Your Information
                </a>
                .
              </p>
            </main>
          </section>
          <section id="use">
            <main>
              <h2>How We Use Your Information</h2>
              <p>
                We use information that we collect about you or that you provide
                to us, including any personal information:
              </p>
              <ul>
                <li>
                  <p>To present our Website and its contents to you.</p>
                </li>
                <li>
                  <p>
                    To provide you with information, products, or services that
                    you request from us.
                  </p>
                </li>
                <li>
                  <p>To fulfill any other purpose for which you provide it.</p>
                </li>
                <li>
                  <p>
                    To provide you with notices about your account, including
                    expiration and renewal notices.
                  </p>
                </li>
                <li>
                  <p>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </p>
                </li>
                <li>
                  <p>
                    To notify you about changes to our Website or any products
                    or services we offer or provide though it.
                  </p>
                </li>
                <li>
                  <p>
                    To allow you to participate in interactive features on our
                    Website.
                  </p>
                </li>
                <li>
                  <p>
                    In any other way we may describe when you provide the
                    information.
                  </p>
                </li>
                <li>
                  <p>For any other purpose with your consent.</p>
                </li>
              </ul>
              <p>
                We may also use your information to contact you about our own
                and third parties' goods and services that may be of interest to
                you. If you do not want us to use your information in this way,
                please let us know via the mechanisms provided, including the
                unsubscribe link in our email communications. For more
                information, see{" "}
                <a href="#choices">
                  Choices About How We Use and Disclose Your Information
                </a>
                .
              </p>
            </main>
          </section>
          <section id="disclosure">
            <main>
              <h2>Disclosure of Your Information</h2>
              <p>
                We may disclose personal information that we collect or you
                provide as described in this privacy policy:
              </p>
              <ul>
                <li>
                  <p>To our subsidiaries and affiliates.</p>
                </li>
                <li>
                  <p>
                    To contractors, service providers, and other third parties
                    we use to support our business and who are bound by
                    contractual obligations to keep personal information
                    confidential and use it only for the purposes for which we
                    disclose it to them.
                  </p>
                </li>
                <li>
                  <p>
                    To a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Varia, Inc's
                    assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal
                    information held by Varia, Inc about our Website users is
                    among the assets transferred.
                  </p>
                </li>
                <li>
                  <p>To fulfill the purpose for which you provide it.</p>
                </li>
                <li>
                  <p>
                    For any other purpose disclosed by us when you provide the
                    information.
                  </p>
                </li>
                <li>
                  <p>With your consent.</p>
                </li>
              </ul>
              <p>We may also disclose your personal information:</p>
              <ul>
                <li>
                  <p>
                    To comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request.
                  </p>
                </li>
                <li>
                  <p>For billing and collection purposes.</p>
                </li>
                <li>
                  <p>
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of Varia, Inc our
                    customers, or others. This includes exchanging information
                    with other companies and organizations for the purposes of
                    fraud protection and credit risk reduction.
                  </p>
                </li>
              </ul>
            </main>
          </section>
          <section id="choices">
            <main>
              <h2>Choices About How We Use and Disclose Your Information</h2>
              <p>
                We strive to provide you with choices regarding the personal
                information you provide to us. We have created mechanisms to
                provide you with the following control over your information:
              </p>
              <ul>
                <li>
                  <p>
                    Tracking Technologies and Advertising. You can set your
                    browser to refuse all or some browser cookies, or to alert
                    you when cookies are being sent.
                  </p>
                </li>
                <li>
                  <p>
                    Promotional Offers from the Company. If you do not wish to
                    have your email address used by the Company to promote our
                    own or third parties' products or services, you can opt-out
                    by sending us an email stating your request to
                    <a href="mailto:optout@variametrix.com">
                      optout@variametrix.com
                    </a>
                    . If we have sent you a promotional email, you may send us a
                    return email asking to be omitted from future email
                    distributions. This opt out does not apply to information
                    provided to the Company as a result of a product purchase,
                    warranty registration, product service experience or other
                    transactions.
                  </p>
                </li>
                <li>
                  <p>
                    Targeted Advertising. If you do not want us to use
                    information that we collect or that you provide to us to
                    deliver advertisements according to our advertisers'
                    target-audience preferences, you can opt-out by emailing us
                    at
                    <a href="mailto:optout@variametrix.com">
                      optout@variametrix.com
                    </a>{" "}
                    and request your name, email, and phone number are excluded
                    from targeted advertising. For this opt-out to function, you
                    must have your browser set to accept all browser cookies.
                  </p>
                </li>
              </ul>
              <p>
                We do not control third parties' collection or use of your
                information to serve interest-based advertising. However these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can opt out
                of receiving targeted ads from members of the Network
                Advertising Initiative ("NAI") on the NAI's website.
              </p>
              <p>
                California residents may have additional personal information
                rights and choices. Please see{" "}
                <a href="#california">Your California Privacy Rights</a> for
                more information.
              </p>
            </main>
          </section>
          <section id="accessing">
            <main>
              <h2>Accessing and Correcting Your Information</h2>
              <p>
                You may also send us an email at
                <a href="mailto:corrections@variametrix.com">
                  corrections@variametrix.com
                </a>
                to request access to, correct or delete any personal information
                that you have provided to us. We cannot delete your personal
                information except by also deleting your user account. We may
                not accommodate a request to change information if we believe
                the change would violate any law or legal requirement or cause
                the information to be incorrect.
              </p>
              <p>
                California residents may have additional personal information
                rights and choices. Please see{" "}
                <a href="#california">Your California Privacy Rights</a> for
                more information.
              </p>
            </main>
          </section>
          <section id="california">
            <main>
              <h2>Your California Privacy Rights</h2>
              <p>
                If you are a California resident, California law may provide you
                with additional rights regarding our use of your personal
                information.
              </p>
              <p>
                California's "Shine the Light" law (Civil Code Section 1798.83)
                permits users of our product that are California residents to
                request certain information regarding our disclosure of personal
                information to third parties for their direct marketing
                purposes. To make such a request, please send an email to
                <a href="mailto:privacy@variametrix.com">
                  privacy@variametrix.com
                </a>
                .
              </p>
            </main>
          </section>
          <section id="data-security">
            <main>
              <h2>Data Security</h2>
              <p>
                We have implemented measures designed to secure your personal
                information from accidental loss and from unauthorized access,
                use, alteration, and disclosure. All information you provide to
                us is stored on our secure servers behind firewalls. Any payment
                transactions will be encrypted using SSL technology.
              </p>
              <p>
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Website, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Website. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website.
              </p>
            </main>
          </section>
          <section id="changes">
            <main>
              <h2>Changes to Our Privacy Policy</h2>
              <p>
                It is our policy to post any changes we make to our privacy
                policy on this page. If we make material changes to how we treat
                our users' personal information, we will post a notice
                promintently and permanently on our blog detailing the change.
                The date the privacy policy was last revised is identified at
                the bottom of this page. You are responsible for ensuring we
                have an up-to-date active and deliverable email address for you,
                and for periodically visiting our Website and this privacy
                policy to check for any changes.
              </p>
            </main>
          </section>
          <section id="contact">
            <main>
              <h2>Contact Information</h2>
              <p>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at:
              </p>
              <p>
                {" "}
                Box 99<br />
                Kirkland, WA 98083
                <br />
                <a href="mailto:privacy@variametrix.com">
                  privacy@variametrix.com
                </a>
                <br />
                <a href="tel:+18882030423">(888) 203 0423</a>
              </p>
            </main>
          </section>
        </main>
      </article>
    </Layout>
  )
}
